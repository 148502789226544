<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div class="container">
    <div class="row">
      <div class="col localeChanger">
        <div class="input-wrap">
          <div class="input-shape">
            <select class="form-select" @change="reloadLanguage" v-model="language">
              <option
                v-for="(lang, i) in $root.languages"
                :key="`locale-${i}`"
                :value="lang"
              >
                {{ lang }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<script>
export default {
  name: "LanguageComponent",
  data() {
    return {
      language: this.language || "cs",
    };
  },
  methods: {
    reloadLanguage() {
      let root = this.$root;
      root.appLoaded = false;
      root.locale = this.language;
      root.updateLangParam(this.language);

      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
  },
  mounted() {
    let root = this.$root;
    let routeLang = this.$route.params.lang;
    if (routeLang) {
      this.language = routeLang;
      root.locale = routeLang;
    }
  },
};
</script>
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped>
#app {
  .localeChanger {
    position: relative;
    width: 100px;
    float: right;
    top: -80px;
    right: 20px;
    .input-wrap {
      position: absolute;
      right: 0;
      top: 20px;
      width: 100px;
      .input-shape {
        width: 100px;
        .form-select {
          width: 100px;
        }
      }
    }

    @media (max-width: 575.98px) {
      float: none;
    }
  }
}
</style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
