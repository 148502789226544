<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <fieldset
    v-if="
      ['mobile'].includes($root.appType) &&
      !$root.formDisabledForce &&
      ['loss', 'damage', 'missing_content', 'substitution', 'delay'].includes(
        $root.reason
      )
    "
    id="stepAffidavit"
    class="steps">
    <h2 class="mb-3">{{ $t("navAffidavit") }}</h2>

    <div class="row">
      <div class="col-md-12 col-lg-8">
        <label for="affidavitName" class="form-label">
          {{ $t("affidavitFullName") }} *
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <label for="affidavitName" class="form-label">
          {{ $t("affidavitFullNameFirst") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitName')
              ? $root.hasError('affidavitName')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitName"
              name="affidavitName"
              v-model="$root.affidavitName"
              min="2"
              required
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitName')
                  ? $root.hasError('affidavitName')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitName')"
              @focusin="$root.onFocusIn('affidavitName')"
              @focusout="$root.onFocusOut('affidavitName')" />
          </div>
        </div>
        <InputErrorComponent field="affidavitName" :errors="$root.errors" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <label for="affidavitName" class="form-label">
          {{ $t("affidavitFullNameLast") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitSurname')
              ? $root.hasError('affidavitSurname')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitSurname"
              name="affidavitSurname"
              v-model="$root.affidavitSurname"
              min="2"
              required
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitSurname')
                  ? $root.hasError('affidavitSurname')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitSurname')"
              @focusin="$root.onFocusIn('affidavitSurname')"
              @focusout="$root.onFocusOut('affidavitSurname')" />
          </div>
        </div>
        <InputErrorComponent field="affidavitSurname" :errors="$root.errors" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-8">
        <label for="affidavitAddress" class="form-label">
          {{ $t("affidavitAddress") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitAddress')
              ? $root.hasError('affidavitAddress')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitAddress"
              name="affidavitAddress"
              v-model="$root.affidavitAddress"
              required
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitAddress')
                  ? $root.hasError('affidavitAddress')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitAddress')"
              @focusin="$root.onFocusIn('affidavitAddress')"
              @focusout="$root.onFocusOut('affidavitAddress')" />
          </div>
        </div>
        <InputErrorComponent field="affidavitAddress" :errors="$root.errors" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-4">
        <label for="affidavitConsignedDate" class="form-label">
          {{ $t("affidavitDate") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitConsignedDate')
              ? $root.hasError('affidavitConsignedDate')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitConsignedDate"
              name="affidavitConsignedDate"
              v-model="$root.affidavitConsignedDate"
              disabled
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitConsignedDate')
                  ? $root.hasError('affidavitConsignedDate')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              " />
          </div>
        </div>
        <InputErrorComponent
          field="affidavitConsignedDate"
          :errors="$root.errors" />
      </div>

      <div class="col-md-12 col-lg-4">
        <label for="affidavitConsignedAddress" class="form-label">
          {{ $t("affidavitPlace") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitConsignedAddress')
              ? $root.hasError('affidavitConsignedAddress')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitConsignedAddress"
              name="affidavitConsignedAddress"
              v-model="$root.affidavitConsignedAddress"
              disabled
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitConsignedAddress')
                  ? $root.hasError('affidavitConsignedAddress')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              " />
          </div>
        </div>
        <InputErrorComponent
          field="affidavitConsignedAddress"
          :errors="$root.errors" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-4">
        <label for="affidavitPackageId" class="form-label">
          <span v-html="$t('affidavitPackageId')"></span> *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitPackageId')
              ? $root.hasError('affidavitPackageId')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitPackageId"
              name="affidavitPackageId"
              v-model="$root.$root.packageId"
              disabled
              class="form-control"
              autocomplete="new-password" />
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4">
        <label for="affidavitInsuranceValue" class="form-label">
          {{ $t("affidavitPrice") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitInsuranceValue')
              ? $root.hasError('affidavitInsuranceValue')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitInsuranceValue"
              name="affidavitInsuranceValue"
              v-model="$root.affidavitInsuranceValue"
              disabled
              autocomplete="new-password"
              :class="
                'form-control' +
                ($root.wasFocused('affidavitInsuranceValue')
                  ? $root.hasError('affidavitInsuranceValue')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitInsuranceValue')"
              @focusin="$root.onFocusIn('affidavitInsuranceValue')"
              @focusout="$root.onFocusOut('affidavitInsuranceValue')" />
          </div>
        </div>
        <InputErrorComponent
          field="affidavitInsuranceValue"
          :errors="$root.errors" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-4">
        <label for="affidavitValue" class="form-label">
          {{ $t("affidavitValue") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitValue')
              ? $root.hasError('affidavitValue')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <span class="input-group-text">{{ $root.insuranceCurrency }}</span>
            <input
              type="text"
              id="affidavitValue"
              name="affidavitValue"
              v-model="$root.affidavitValue"
              required
              :class="
                'form-control input-group-input fit-content' +
                ($root.wasFocused('affidavitValue')
                  ? $root.hasError('affidavitValue')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitValue')"
              @focusin="$root.onFocusIn('affidavitValue')"
              @focusout="$root.onFocusOut('affidavitValue')" />
          </div>
        </div>
        <InputErrorComponent field="affidavitValue" :errors="$root.errors" />
      </div>
      <div class="col-md-12 col-lg-4">
        <label for="affidavitBankAccount" class="form-label">
          {{ $t("affidavitBankAccount") }} *
        </label>
        <div
          :class="
            'input-wrap' +
            ($root.wasFocused('affidavitBankAccount')
              ? $root.hasError('affidavitBankAccount')
                ? ' is-invalid'
                : ' is-valid'
              : '')
          ">
          <div class="input-shape">
            <input
              type="text"
              id="affidavitBankAccount"
              name="affidavitBankAccount"
              v-model="$root.affidavitBankAccount"
              required
              :class="
                'form-control' +
                ($root.wasFocused('affidavitBankAccount')
                  ? $root.hasError('affidavitBankAccount')
                    ? ' is-invalid'
                    : ' is-valid'
                  : '')
              "
              @keyup="$root.onKeyUp('affidavitBankAccount')"
              @focusin="$root.onFocusIn('affidavitBankAccount')"
              @focusout="$root.onFocusOut('affidavitBankAccount')" />
          </div>
        </div>
        <InputErrorComponent
          field="affidavitBankAccount"
          :errors="$root.errors" />
      </div>
    </div>

    <div
      v-if="
        ['mobile'].includes($root.appType) &&
        ['cz', 'sk', 'hu'].includes($root.country)
      "
      class="row">
      <div class="col-md-12 col-lg-8">
        <p>
          <span v-html="$t('affidavitAgreementStart')"></span>
          <a
            :href="$root.termsAndConditionsLinks[$root.country].generalMobile"
            target="_blank"
            >{{ $t("affidavitAgreementLinkTitle") }}</a
          >
          <span v-html="$t('affidavitAgreementEnd')"></span>
        </p>
      </div>
    </div>
  </fieldset>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<script>
import InputErrorComponent from "@/components/InputErrorComponent.vue";

export default {
  name: "AffidavitComponent",
  components: {
    InputErrorComponent,
  },
};
</script>
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped>
.fit-content {
  max-width: -webkit-fill-available;
}
</style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
