const PERSON_TYPES = {
  mobile: "typeMobile",
  recipient: "typeRecipient",
  client: "typeClient",
  mk2c: "typeMk2C",
};

const PERSON_TYPES_PER_LANGUAGE = {
  cs: ["mobile", "recipient", "client", "mk2c"],
  de: ["client"],
  hu: ["mobile", "recipient", "client"],
  pl: ["client"],
  ro: ["recipient", "client"],
  sk: ["mobile", "recipient", "client"],
};

export function getPersonTypesByLanguage(lang) {
  if (!(lang in PERSON_TYPES_PER_LANGUAGE)) {
    return PERSON_TYPES;
  }

  let personTypes = {};
  Object.entries(PERSON_TYPES).forEach((entry) => {
    const [key, value] = entry;

    if (PERSON_TYPES_PER_LANGUAGE[lang].includes(key)) {
      personTypes[key] = value;
    }
  });

  return personTypes;
}
