<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <LanguageComponent></LanguageComponent>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <h1 class="mt-7 mb-4">{{ $t("errNotAllowedH1") }}!</h1>
        </div>
      </div>
      <div class="row">
        <div v-for="error in $root.globalErrors" :key="error" class="col-sm-12">
          <div class="alert alert-danger">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<script>
import LanguageComponent from "@/components/LanguageComponent";

export default {
  name: "NotAllowed",
  components: {
    LanguageComponent,
  },
  data() {
    return {};
  },
  mounted() {
    let root = this.$root;
    root.appLoaded = true;
  },
  methods: {},
};
</script>
<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped></style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
