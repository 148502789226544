<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <template v-for="error in errors" v-bind:key="error.field.name">
    <div v-if="error.field.name === field" class="invalid-feedback">
      <div v-html="error.field.message"></div>
    </div>
  </template>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<script>
export default {
  name: "InputErrorComponent",
  props: {
    field: String,
    errors: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped></style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
